import { Event } from '../constructs';

/**
 * Subscribe to get notified after any change to the current route (+hash change)\
 * The event is fired after loading/unloading of modules ends
 *
 * REMINDER: don't forget to unsubscribe
 */
export const routeChangedEvent = new Event<void>();
const emitRouteChangedEvent = routeChangedEvent.init();

window.addEventListener('single-spa:routing-event', (_evt) => {
  emitRouteChangedEvent();
});
