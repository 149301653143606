import { Event } from '../constructs';

export const loadingDoneEvent = new Event<void>();
const triggerLoadingDone = loadingDoneEvent.init();
let deferCount = 0;

/**
 * Basic mechanism for delaying the loading event\
 * Designed to work with the loading animation\
 * Any mfe can call defer & done to keep the full page load animation on until it is ready\
 * In any case, the mfe still needs to take care of an internal loader (for SPA navigation)
 */
export function deferLoading() {
  deferCount++;
}

/**
 * Indicate that loading of current mfe/process is done\
 * Will trigger the event when defer count is down to zero\
 *
 * Call this method within a finally block!
 */
export function loadingDone() {
  deferCount--;

  if (deferCount === 0) triggerLoadingDone();
}
