import { Activity, registerApplication } from 'single-spa';

export type RegisterAppOptions = {
  name: string;
  path: string;
  includeSubRoutes?: boolean;
};

/**
 * Register an mfe's global routing\
 * Determines when the module is mounted/unmounted
 *
 * @param name The package name without the prefix
 * @param activeWhen The relative base route where the mfe resides (ex. /v1/settings) - see [registerApp](https://single-spa.js.org/docs/api/#registerApplication)
 */
export async function registerApp(name: string, activeWhen: Activity) {
  registerApplication({
    name,
    app: () => import(/* @vite-ignore */ '@mfe/' + name),
    activeWhen,
  });
}
