/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
 */
function beforeUnloadHandler(event: BeforeUnloadEvent) {
  // Recommended
  event.preventDefault();

  // Included for legacy support, e.g. Chrome/Edge < 119
  event.returnValue = true;
}

// for internal use only - this file and navigate.ts
export let isPreventUnloadEnabled = false;

/**
 * Uses the [native browser support](https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event) for
 * preventing the user from navigating away when there are unsaved changes\
 * Also prevents reloading/closing the tab, closing the browser and using the back/forward buttons
 *
 * Only call this method when there are ACTUAL unsaved changes, call `releasePageUnload()` as soon as changes are saved!
 */
export function preventPageUnload() {
  isPreventUnloadEnabled = true;
  window.addEventListener('beforeunload', beforeUnloadHandler);
}

/**
 * Call this function as soon as changes are saved\
 * (after you called `preventPageUnload()`)
 */
export function releasePageUnload() {
  isPreventUnloadEnabled = false;
  window.removeEventListener('beforeunload', beforeUnloadHandler);
}
