import { Unsub } from './types';

export enum Priority {
  Low = 1000,
  Medium = 2000,
  High = 3000,
  Default = Priority.Low,
}

export type PriorityArrayOptions = {
  priority: Priority | number;
};

const defaultOptions: PriorityArrayOptions = {
  priority: Priority.Default,
};

export class PriorityArray<T> {
  private _items = new Array<T>();
  private options = new Map<T, PriorityArrayOptions>();

  public add(item: T, options?: PriorityArrayOptions): Unsub {
    options = options || defaultOptions;
    if (this.options.has(item))
      throw new Error('duplicate item: ' + JSON.stringify(item));

    this.options.set(item, options);
    this._items.push(item);

    this.sort();

    return () => {
      this.remove(item);
      this.sort();
    };
  }

  public remove(item: T): boolean {
    const index = this._items.findIndex((anItem) => anItem === item);

    if (index === -1) return false;

    this._items.splice(index, 1);
    const deleted = this.options.delete(item);

    if (!deleted)
      console.error('PriorityArray: could not delete options for item', item);

    return true;
  }

  private sort(): void {
    this._items.sort((a, b) => {
      const aPriority = this.options.get(a)?.priority || Priority.Default;
      const bPriority = this.options.get(b)?.priority || Priority.Default;

      return bPriority - aPriority;
    });
  }

  public get items(): T[] {
    return this._items;
  }
}
