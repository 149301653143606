import { Event, Subscriber } from './event';
import { PriorityArray, PriorityArrayOptions } from './priorities';
import { Unsub } from './types';

/** Registration options are currently the same as the priority array */
export type ExtensionPointOptions = PriorityArrayOptions;

/**
 * IoC basic constructs\
 * Exposes an place for modules to register something\
 * The something can be a simple object (ex. menu entry - name and handler) or\
 * a component to be dynamically created within and MFE
 *
 * The `ExtensionPoint` allows subscribers to control the order using Priorities\
 * Providers of extension points should subscribe for changes\
 *
 * See the attached .spec.ts for tests and the header MFE for real world examples
 */
export class ExtensionPoint<T> {
  private extensionPoints = new PriorityArray<T>();
  private changeEvent = new Event<T[]>();
  private changeEmitter = this.changeEvent.init();

  /**
   * register an item\
   * order can be controlled by passing options - `{ priority: Priority.High }`
   * @returns A method to remove the item
   */
  public register(item: T, options?: ExtensionPointOptions): Unsub {
    const unsub = this.extensionPoints.add(item, options);

    this.changeEmitter(this.extensionPoints.items);

    return () => {
      unsub();
      this.changeEmitter(this.extensionPoints.items);
    };
  }

  /**
   * Subscribe to change events\
   * Usually only used by the provider of the extension point
   * @param subscriber The event handler
   * @returns Remove the subscription
   */
  public subscribe(subscriber: Subscriber<T[]>): Unsub {
    // Auto emit on subscription
    const items = this.extensionPoints.items;
    if (items.length) subscriber(items);

    return this.changeEvent.subscribe(subscriber);
  }
}
